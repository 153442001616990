@charset "utf-8";

$primary: #0d3056;
$accent: #f0da07;

$navbar-height: 8rem;
$navbar-padding-vertical: 4rem;
$navbar-item-color: #fff;
$navbar-item-active-color: $accent;
$navbar-item-hover-color: lighten($accent, 20%);
$navbar-item-hover-background-color: transparent;


@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";



@import "../node_modules/bulma/sass/grid/_all.sass";

.resource[data-badge].has-badge-left::after {
  background-color: #4575c3;
  box-shadow: none;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  left: 5px;
  top: 5px;
  width: 35px;
  height: 35px;
  z-index: 1; }

h4 {
  color: #000000; }

.no-scroll {
  overflow-y: hidden; }


@import "../node_modules/bulma/sass/components/navbar";

@import "../node_modules/bulma-badge/src/sass/index.sass";

.hero.b1 {
  background-image: none !important;
  background: darken($primary, 2%); }


.hero.b2 {
  background-image: none !important;
  background: lighten($primary, 2%); }








@import "../node_modules/bulma/sass/components/level";

@import "../node_modules/bulma/sass/components/modal";

@media screen and (min-width: 769px) {
  .modal-content, .modal-card {
    width: 100%; } }

.modal-content {
  overflow: hidden; }


@import "../node_modules/bulma/sass/components/card";


a:hover, a:focus {
  color: darken($link, 10%); }

@import "../node_modules/bulma/sass/components/media";

@import "../node_modules/bulma/sass/components/tabs";

@import "../node_modules/bulma/sass/components/menu";





body {
  min-height: 100vh;
  font-weight: lighter; }

.button.is-primary.get-started-button {
  background-color: darken($primary, 10%); }

.button.is-primary.reach-over-button {
  background-color: $primary;
  opacity: 0.95; }

.button.is-primary.reach-over-button:hover {
  background-color: darken($primary, 5%); }

.button.is-primary.get-started-button:hover {
  background-color: darken($primary, 8%); }

$home-opacity: 0.6;
$home-image: "/images/background.jpg";



.navbar.is-absolute {
  position: absolute;
  width: 100%;
  background: transparent; }

.navbar {
  background-image: linear-gradient(to right, rgba($primary, $home-opacity) 30%, transparent 100%), url($home-image); }

.navbar a.navbar-item {
  margin: 0 20px;
  font-weight: 500; }


.navbar.is-active .container {
  background-color: rgba(0,0,0,.3); }

.navbar .navbar-menu.is-active {
  background: transparent; }

.navbar-burger:hover {
  background: transparent;
  color: $accent; }

.home-image {
  background-image: linear-gradient(to right, rgba($primary, $home-opacity) 30%, transparent 100%), url($home-image);
  background-size: cover;
  padding-top: 100px; }

.hero button, .hero button:hover, .hero button:active, .hero button:focus {
  background-color: transparent;
  line-height: 0;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0;
  color: #fff; }




.home-image h1.title {
  font-size: 50px;
  margin-top: 10px; }

.home-image h1.title.empowered {
  color: $accent;
  margin-bottom: 60px;
  font-size: 60px; }

.home-image .button {
  margin-top: 40px; }



.resource-section h1 {
  font-weight: 200;
  margin: 40px 0 40px 0; }


.video-description {
  margin: 8px 20px;
  padding: 20px;
  background-color: #ffffff; }

.partners img {
  width: 200px;
  margin: 40px; }

body {
  background-color: #efefef; }
